<template>
    <div>
        <multiselect
            class="mb-2 multSelectGesto"                                                    
            open-direction="bottom" 
            v-model="opcionSeleccionadaFiltro" 
            :options="opcionesFiltrosTrabajadores" 
            select-label=""
            selectedLabel=""
            deselect-label=""
            selectGroupLabel=""
            deselectGroupLabel=""
            :multiple="true" 
            group-values="opcionesFiltro" 
            group-label="filtroNombre" 
            :group-select="true" 
            placeholder="Seleccione un filtro" 
            track-by="unico" 
            label="nombre"
            :show-labels="true"
            @input="actualizarFiltroTrabajadores">
            
            <span slot="noResult">Oops! no se encuentran filtros con esa palabra</span>
            <template slot="tag" slot-scope="props">
                <span class="multiselect__tag">
                    <span>({{props.option.padre}}) {{props.option.nombre}}</span> 
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTag(props.option)"></i>
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <span v-if="'$groupLabel' in props.option"><span class="text-capitalize font-weight-bold">{{props.option.$groupLabel}}</span> (Seleccionar/quitar todos)</span>
                <span v-else>{{props.option.nombre}}</span>
            </template>
        </multiselect>
    </div>
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";
    
    import * as util from '@/views/utils/utilsFunctions'
    import apiUrls from '@/views/utils/apiUrls'

    export default {
        name: "OpcionesFiltrosTrabajadores",
        props: {
            opcionesFiltrosTrabajadores: Array
        },
        components: {
            Multiselect
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port + "://" + cons.ipServer,
                opcionSeleccionadaFiltro: null
            

            };
        },
        created: function () {
        },
        beforeMount() {
            
        },
        methods: {
            actualizarFiltroTrabajadores: function(){
                //this.obtenerTrabajadores(true, empresa)
                this.$emit('seleccionarFiltro', this.opcionSeleccionadaFiltro)
            },
            quitarTag: function(tag){
                this.opcionSeleccionadaFiltro.splice(this.opcionSeleccionadaFiltro.indexOf(tag), 1)
                this.actualizarFiltroTrabajadores()
            },

        },
    };
</script>


<style scoped>
</style>