<template>
    <div>
        
        <CRow>
            <CCol md="12" lg="12">
                <CCard class="tarjetaGesto">
                    <CCardHeader><h4>Contratos y Finiquitos</h4>
                    </CCardHeader>
                    <CCardBody>
                        
                        <CRow>
                            <CCol md="3" sm="12"><!--select Empresa-->
                                <multiselect
                                    class="multSelectGesto mb-1"
                                    v-model="empresaSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Empresas" 
                                    label="nombreEmpresa" 
                                    track-by="codEmpresa" 
                                    :options="empresas" 
                                    :multiple="false"
                                    @input="seleccionarEmpresa">
                                </multiselect>
                            </CCol>
                            <CCol md="2" sm="12"><!--select tipo Dcto-->
                                <multiselect class="d-inline-block multSelectGesto mb-1"
                                    v-show="tipoDocumentos.length>1"
                                    v-model="tipoDocumentoSelected"
                                    deselect-label="" 
                                    label="nombreVisible"
                                    placeholder="Documento"
                                    select-label=""
                                    selectedLabel=""
                                    :options="tipoDocumentos" 
                                    :searchable="true"
                                    :loading="isLoadingTipoDocumentos"
                                    :content="errorDctos==null?'Seleccionar documento':errorDctos"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                    }"
                                    @input="seleccionOpcionHeaders">
                                </multiselect>
                            </CCol>
                            <CCol md="2" sm="12" v-show="tipoDocumentoSelected != null" ><!--mostrar periodo-->
                                por periodo 
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2"
                                    shape="pill"
                                    variant="outline"
                                    :checked="mostrarPeriodo"
                                    v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                    @update:checked="seleccionarMostraPeriodo"
                                />
                            </CCol>
                            <CCol md="2" sm="12" v-show="(tipoDocumentoSelected != null) ? mostrarPeriodo : false"><!--select año-->
                                <multiselect class="m-0 d-inline-block mb-1 multSelectGesto" 
                                    v-model="periodoSelected"
                                    deselect-label="" 
                                    label="nombrePeriodo" 
                                    placeholder="Periodo" 
                                    select-label=""
                                    selectedLabel=""
                                    :options="periodos" 
                                    :searchable="true"
                                    :loading="isLoadingPeriodos"
                                    :disabled="(tipoDocumentoSelected == null || empresaSelected == null)?true:false"
                                    @input="seleccionOpcionHeaders">
                                </multiselect>
                            </CCol>
                            <CCol md="2" sm="12" v-show="(tipoDocumentoSelected != null) ? mostrarPeriodo : false"><!--select mes-->
                                <multiselect class="m-0 d-inline-block mb-1 multSelectGesto" 
                                    v-model="mesSelected"
                                    deselect-label="" 
                                    label="nombreMes" 
                                    placeholder="Mes" 
                                    select-label=""
                                    selectedLabel=""
                                    :options="(periodoSelected == null)?[]:periodoSelected.meses"
                                    :searchable="true"
                                    :loading="isLoadingMes"
                                    :disabled="(periodoSelected == null)?true:false"
                                    @input="seleccionOpcionHeaders">
                                </multiselect>
                            </CCol>
                            <CCol md="3" sm="12">
                                <CButton 
                                    size="sm"
                                    class="btnPrincipalVapp"
                                    @click="seleccionOpcionHeaders"
                                    :disabled="isLoadingTrabajadores"
                                    v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                            content: 'Recargar Información'
                                        }">                                    
                                    <span class="pi pi-sync mt-1" :class="isLoadingTrabajadores?'imgr':''" style="font-size: 0.7rem;"></span>
                                </CButton>
                            </CCol>  
                        </CRow>
                        <CRow v-if="tipoDocumentoSelected != null && empresaSelected != null && (mostrarPeriodo ? (mesSelected!=null && periodoSelected != null):true)">
                            <CCol sm="2" md="2" lg="1"> <!--Mensajes generacion, opciones documentos-->
                                
                                <span>
                                    <CDropdown 
                                        color="drpdwn-menu"
                                        class="mt-1 d-inline-block mb-1"
                                        size="sm"
                                        :content="'Opciones '+tipoDocumentoSelected.nombreVisible"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }">
                                        <template #toggler-content>
                                            <CIcon :content="$options.freeSet.cilApplications" :class="(isGenerando)?'imgr':''"
                                                :style="{color:'green'}" /><!-- Opciones {{ tipoDocumentoSelected.nombreVisible }}-->
                                        </template>
                                        <div role="group">
                                            <CDropdownItem v-if="tipoDocumentoSelected.nombreTipoDocumento == 'finiquitos'"
                                                @click="abrirModalTerminoContrato()">
                                                Termino de Contrato Trabajadores
                                            </CDropdownItem>
                                            <CDropdownItem v-if="tipoDocumentoSelected.nombreTipoDocumento == 'contratos'"
                                                @click="abrirModalGeneracionContrato()">
                                                Generación Contrato Trabajadores
                                            </CDropdownItem>
                                            <!--<CDropdownItem @click="abrirModalGeneracionDocumentos" :disabled="empresaSelected!=null?empresaSelected.trabajadoresSeleccionados.length<=0:true">
                                                Generar Documentos {{ tipoDocumentoSelected.nombreVisible }} Trabajador
                                            </CDropdownItem>-->
                                            <!--<CDropdownItem :disabled="empresaSelected!=null?empresaSelected.trabajadoresSeleccionados.length<=0:true" @click="convertirPdf('generarDocumentos')">
                                                Convertir documentos (docs) a PDF
                                            </CDropdownItem>-->
                                            <!--<CDropdownItem v-if="tipoDocumentoSelected.nombreTipoDocumento == 'finiquitos'" :disabled="empresaSelected!=null?empresaSelected.trabajadoresSeleccionados.length<=0:true"
                                                @click="abrirModalTerminoContrato('finiquitarGenerar')">
                                                Termino de Contrato y Generar Finiquitos trabajadores
                                            </CDropdownItem>-->
                                            
                                            <CDropdownDivider /> 

                                            <CDropdownItem @click="mostrarGestionTemplates">
                                                Gestionar Templates Empresa {{ empresaSelected.nombreEmpresa }}
                                            </CDropdownItem>
                                        </div>
                                    </CDropdown>
                                </span>
                                <span>
                                    <a v-show="mensajesGeneracion.length > 0" 
                                        style="text-decoration: none; color:#0b7689" class="ml-1 mr-1"
                                        :content="verRespuestaGeneracion()" 
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }">
                                        <!--<CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />-->
                                        <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                            
                                    </a>
                                    <!--<CButton
                                        class="mr-1"
                                        style="width:auto"
                                        size="sm"
                                        v-show="mensajesGeneracion.length > 0"
                                        @click="mostrarMensajes = !mostrarMensajes"
                                        content="Mostrar/Ocultar Mensajes"
                                        v-tippy="{
                                            placement: 'right',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google' 
                                        }">
                                        <CIcon v-show="mostrarMensajes" :content="$options.freeSet.cilChevronTop" />
                                        <CIcon v-show="!mostrarMensajes" :content="$options.freeSet.cilChevronBottom" />
                                    </CButton>-->
                                </span>
                            </CCol>
                            <CCol sm="4" md="3" lg="2"><!--Opciones Filtros-->
                                <OpcionesFiltrosTrabajadores 
                                    :opcionesFiltrosTrabajadores="empresaSelected.opcionesFiltrosTrabajadores"
                                    @seleccionarFiltro="seleccionFiltro($event)"
                                 />
                                                
                                <!--<multiselect
                                    :id="empresaSelected"
                                    class="ml-2 mb-2 multSelectGesto"
                                    v-model="empresaSelected.opcionSeleccionadaFiltro" 
                                    :options="empresaSelected.opcionesFiltrosTrabajadores" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    group-values="opcionesFiltro" 
                                    group-label="filtroNombre" 
                                    :group-select="true" 
                                    placeholder="Filtrar" 
                                    track-by="unico" 
                                    label="nombre"
                                    :show-labels="true"
                                    @input="actualizarFiltroOpciones">
                                    
                                    <span slot="noResult">Oops! no se encuentran filtros con esa palabra</span>
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>({{props.option.padre}}) {{props.option.nombre}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTag(props.option, empresaSelected)"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span v-if="'$groupLabel' in props.option"><span class="text-capitalize font-weight-bold">{{props.option.$groupLabel}}</span> (Seleccionar/quitar todos)</span>
                                        <span v-else>{{props.option.nombre}}</span>
                                    </template>
                                </multiselect>-->
                            </CCol>
                        </CRow>
                        <!--<CRow v-if="tipoDocumentoSelected != null && empresaSelected != null && (mostrarPeriodo ? (mesSelected!=null && periodoSelected != null):true)">
                            <CCol col="6">
                                <a-table
                                    v-show="mostrarMensajes"
                                    row-key="codEmpresa"
                                    :columns="columnasMensajesGeneracion" 
                                    :data-source="mensajesGeneracion"
                                    :scroll="{ y: 300 }"
                                    class="components-table-demo-nested mt-3" 
                                    size="small" 
                                    style="overflow:auto;">
                                    <template slot="mensajes" slot-scope="mensaje">
                                        {{ mensaje }}
                                    </template>
                                </a-table>
                            </CCol>
                        </CRow>-->
                        <CargandoInfo :mostrarCarga="isLoadingTrabajadores" :mensajeInfo="'Obteniendo Trabajadores'" />
                        
                        <CRow v-show="!isLoadingTrabajadores" v-if="tipoDocumentoSelected != null && empresaSelected != null && (mostrarPeriodo ? (mesSelected!=null && periodoSelected != null):true)">
                            
                            <CCol>
                                <vs-table v-if="empresaSelected != null && empresaSelected.trabajadores.length>0" striped>
                                    <template #header>
                                        <vs-input
                                            v-model="busquedaTrabajadores"
                                            border
                                            placeholder="Buscar"
                                        />
                                    </template>
                                    <template #thead>
                                        <vs-tr>
                                            <vs-th>
                                                <CSwitch
                                                    width="150px"
                                                    class="pillTbl mx-1 align-middle mb-2"
                                                    shape="pill"
                                                    variant="outline"
                                                    :checked="allCheck"
                                                    :indeterminate="empresaSelected.trabajadoresSeleccionados.length >0 && empresaSelected.trabajadoresSeleccionados.length < empresaSelected.trabajadores.length" 
                                                    @update:checked="seleccionarTodos()"
                                                />
                                            </vs-th>
                                            <vs-th
                                                v-for="col in columnasTrabajadores"
                                                :style="{ width: col.width }"
                                                v-bind:key="col.key"
                                                sort
                                                @click="empresaSelected.trabajadores = $vs.sortData($event, empresaSelected.trabajadores, col.key)"
                                            >
                                                {{ col.title }}
                                            </vs-th><!--Columnas Trabajadores-->
                                        </vs-tr>
                                    </template>
                                    <template #tbody>
                                        <vs-tr   
                                            :key="i" v-for="(trabajador, i) in $vs.getPage($vs.getSearch(empresaSelected.trabajadores, busquedaTrabajadores), page, max)"
                                            :data="trabajador"
                                            :is-selected="!!trabajador.seleccionado"
                                            not-click-selected
                                            open-expand-only-td
                                        >
                                            <vs-td checkbox>
                                                <CSwitch
                                                    width="150px"
                                                    class="pillTbl mx-1 align-middle mb-2"
                                                    shape="pill"
                                                    variant="outline"
                                                    :checked="trabajador.seleccionado"
                                                    @update:checked="seleccionarTrabajador(trabajador)"
                                                />
                                                <!--empresaSelected.trabajadoresSeleccionados.includes(trabajador)-->
                                                <!--<vs-checkbox :val="trabajador" v-model="trabajador.seleccionado" @change="seleccionarTrabajador(trabajador)" />-->
                                                <!--v-model="empresa.trabajadoresSeleccionados"-->
                                            </vs-td>
                                            <vs-td>
                                                <span :style="(trabajador.documentos.length>0)?{'color':'#0b7689'}:''">{{$utils.formatearRut(trabajador.rutTrabajador)}} </span>
                                            </vs-td>
                                            <vs-td>
                                                <span :style="(trabajador.documentos.length>0)?{'color':'#0b7689'}:''">{{trabajador.nombreTrabajador}}</span>
                                            </vs-td>
                                            <vs-td>
                                                <span v-if="trabajador.isLoadingGenerarDocumento"><CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" /> generando...</span>
                                                <span v-else-if="trabajador.documentos.length>0 && !trabajador.hayDocumentos">
                                                    <a v-show="tipoDocumentoSelected.nombreTipoDocumento == 'contratos'"
                                                        target="_blank"
                                                        style="text-decoration: none; color:#0b7689;"
                                                        @click="seleccionarGenerarDocsTrabajador(trabajador)"
                                                        content="Generar Documentos"
                                                            v-tippy="{
                                                                placement: 'top',
                                                                arrow: true,
                                                                arrowType: 'round',
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                        >
                                                        <CIcon :content="$options.freeSet.cilLibraryAdd" />
                                                    </a>
                                                </span>
                                                <a v-else
                                                    style="text-decoration: none; color:#0b7689" 
                                                    v-show="trabajador.hayDocumentos"
                                                    @click="seleccionarTrabajadores(empresaSelected, trabajador, null)"
                                                    content="Ver Documentos"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }">
                                                    <CIcon :content="$options.freeSet.cilZoomIn" />
                                                </a>
                                            </vs-td>
                                            <template #expand>
                                                <div class="pl-4">
                                                    <CRow>
                                                        <CCol>
                                                            <a-table
                                                                row-key="idDocumento"
                                                                :columns="(tipoDocumentoSelected.nombreTipoDocumento == 'contratos')?columnasArchivosContratos:columnasArchivosFiniquitos" 
                                                                :data-source="trabajador.documentos"
                                                                :scroll="{ y: 400 }"
                                                                class="tableInterior mt-3" 
                                                                size="small"
                                                                :locale="{
                                                                    filterConfirm: 'Filtrar',
                                                                    filterReset: 'Limpiar',
                                                                    emptyText: 'Trabajador sin documentos'
                                                                }">
                                                                    
                                                                <div slot="tipoDoc" slot-scope="documento">
                                                                    {{documento.tipo}} {{ documento.tipoContrato }}
                                                                </div>

                                                                <div slot="fechaEmision" slot-scope="documento">
                                                                    {{$utils.formatFechaDocumentoNormal(documento.fechaEmision)}}
                                                                </div>

                                                                <div slot="fechaContrato" slot-scope="documento">
                                                                    {{$utils.formatFechaDocumentoNormal(documento.fechaContrato)}}
                                                                </div>

                                                                <div slot="fechaTermino" slot-scope="documento">
                                                                    {{$utils.formatFechaDocumentoNormal(documento.fechaTermino)}}
                                                                </div>
                                                                
                                                                <div v-if="tipoDocumentoSelected.nombreTipoDocumento == 'contratos'" slot="fechaVencimiento" slot-scope="documento">
                                                                    {{$utils.formatFechaDocumentoNormal(documento.fechaVencimiento)}}
                                                                </div>

                                                                <div v-if="tipoDocumentoSelected.nombreTipoDocumento == 'finiquitos'" slot="causalFiniquito" slot-scope="documento">
                                                                    {{documento.causalFiniquito}}
                                                                </div>
                                                                <div v-if="tipoDocumentoSelected.nombreTipoDocumento == 'finiquitos'" slot="descripcionFiniquito" slot-scope="documento">
                                                                    {{documento.descripcionFiniquito}}
                                                                </div>
                                                                

                                                                <div slot="opciones" slot-scope="documento">
                                                                    <!--{{ documento }} -->
                                                                    <span v-if="documento.isGenerando">
                                                                        <CIcon style="color:#0b7689;" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                                                    </span>
                                                                    <span v-else>
                                                                        <CButtonGroup size="sm" class="mx-1"
                                                                            :content="'Eliminar término de contrato'"
                                                                            v-tippy="{ 
                                                                                placement: 'top',
                                                                                arrow: true, 
                                                                                arrowType: 'round', 
                                                                                animation:'fade',
                                                                                theme: 'google',
                                                                                followCursor : true,
                                                                            }">
                                                                            <CButton 
                                                                                color="danger" size="sm"
                                                                                @click="eliminarTerminoContrato(documento, trabajador)">
                                                                                <CIcon :content="$options.freeSet.cilXCircle" />
                                                                            </CButton>
                                                                        </CButtonGroup>
                                                                    </span>

                                                                </div>
                                                                <div slot="documentos" slot-scope="documento">
                                                                    <span v-if="(documento.documento != null?documento.documento.isLoadingDoc:false) || isGenerando || empresaSelected.isLoadingGenerarDocumento || trabajador.isLoadingGenerarDocumento">
                                                                        <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                                                    </span>
                                                                    <span v-else>
                                                                        <a
                                                                            v-if="documento.documento == null"
                                                                            style="text-decoration: none; color: #292929;"
                                                                            content="Sin Archivo"
                                                                            v-tippy="{ 
                                                                                placement: 'top',
                                                                                arrow: true, 
                                                                                arrowType: 'round', 
                                                                                animation:'fade',
                                                                                theme: 'google',
                                                                                followCursor : true,
                                                                            }">
                                                                            <CIcon :content="$options.freeSet.cilMeh" />
                                                                        </a>
                                                                        <span v-else>
                                                                            <span v-show="!portContador ? documento.documento.estadoDocumento == 1 || documento.documento.estadoDocumento ==3 : true">
                                                                                
                                                                                <a
                                                                                    :href="documento.documento.urlDocumento" 
                                                                                    target="_blank" 
                                                                                    style="text-decoration: none; color:#0b7689;" 
                                                                                    content="Descargar Documento"
                                                                                    v-tippy="{ 
                                                                                        placement: 'top',
                                                                                        arrow: true, 
                                                                                        arrowType: 'round', 
                                                                                        animation:'fade',
                                                                                        theme: 'google',
                                                                                        followCursor : true,
                                                                                    }"
                                                                                >
                                                                                    <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                                                </a>
                                                                                |
                                                                                <a
                                                                                    style="text-decoration: none; color:#0b7689"
                                                                                    content="Ver Documento"
                                                                                    v-tippy="{ 
                                                                                        placement: 'top',
                                                                                        arrow: true, 
                                                                                        arrowType: 'round', 
                                                                                        animation:'fade',
                                                                                        theme: 'google',
                                                                                        followCursor : true,
                                                                                    }"
                                                                                    @click="seleccionarTrabajadores(empresaSelected, trabajador, documento.documento)">
                                                                                    <CIcon :content="$options.freeSet.cilZoomIn" />
                                                                                </a>
                                                                            </span>
                                                                            <span v-if="((portContador)?documento.documento.estadoDocumento != 1 && documento.documento.estadoDocumento != 3 : false)">
                                                                                |
                                                                                <a  
                                                                                    style="text-decoration: none; color:#0b7689" 
                                                                                    content="Eliminar Documento"
                                                                                    v-tippy="{ 
                                                                                        placement: 'top',
                                                                                        arrow: true, 
                                                                                        arrowType: 'round', 
                                                                                        animation:'fade',
                                                                                        theme: 'google',
                                                                                        followCursor : true,
                                                                                    }"
                                                                                    @click="eliminarDocumento(empresaSelected, trabajador, documento)">
                                                                                    
                                                                                    <CIcon :content="$options.freeSet.cilXCircle" />
                                                                                </a>
                                                                            </span>
                                                                        </span>
                                                                        <span v-if="((portContador)?(documento.documento != null ? documento.documento.estadoDocumento != 1 && documento.documento.estadoDocumento != 3 : true) : false)">
                                                                        |
                                                                        <a
                                                                            @click="abrirModalSubirDoc(documento, trabajador)"
                                                                            style="text-decoration: none; color:#0b7689"
                                                                            content="Subir Documento"
                                                                            v-tippy="{ 
                                                                                placement: 'top',
                                                                                arrow: true, 
                                                                                arrowType: 'round', 
                                                                                animation:'fade',
                                                                                theme: 'google',
                                                                                followCursor : true,
                                                                            }">
                                                                            <CIcon :content="$options.freeSet.cilCloudUpload" />
                                                                        </a>
                                                                    </span>
                                                                    </span>
                                                                </div>
                                                                
                                                                    
                                                                <div slot="estado" slot-scope="documento">
                                                                        <span v-if="!documento.documento">
                                                                            <CBadge shape="pill" color="secondary">
                                                                                Sin Estado 
                                                                            </CBadge>
                                                                        </span>
                                                                        <span v-else>
                                                                            
                                                                            <CBadge shape="pill" :color="$utils.getColorEstadoDocumento(documento.documento)">
                                                                                {{ getEstadoDocumento(documento.documento) }}                                                               
                                                                            </CBadge>
                                                                        </span>
                                                                    </div>
                                                            </a-table>
                                                        </CCol>
                                                    </CRow>
                                                </div>
                                            </template>
                                        </vs-tr>

                                    </template>
                                </vs-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <GestionDocumentos v-if="empresaSelected != null && tipoDocumentoSelected != null" :padre="this" :mostrarGestionDocumentos="mostrarSubirArchivos" :empresa="empresaSelected" :tipoDocumentoSelected="tipoDocumentoSelected" />
        
        <ModalDocsView
            v-if="mostrarVisorPDF"
            :prevue="mostrarVisorPDF"
            :trabajadorprev="trabajadorSelectedModal"
            :empresa="empresaSelModal"
            :documento="documentoSelectedModal"
            :documentos="documentosTrabajadorModal"
            :periodoSelected="periodoSelected"
            :mesSelected="mesSelected"
            :tipoDocumentoSelected="tipoDocumentoSelected"
            :web="this"
        />
        <ModalSubirDocTrabajador 
            :mostrarModal="mostrarModalSubirDoc"
            :linkSubirArchivoTrabajador="linkSubirArchivoTrabajador"
            @cerrarModal="cerrarMostrarSubirDoc"
            @recibirDocumentoActualizado="actualizarDocumento"
            @obtenerTrabajadores="obtenerTrabajadores(empresaSelected)" />
        
        <ModalTerminoContrato :mostrarModal="mostrarTerminoContrato" :empresaSelected="empresaSelected" 
            :trabajadores="(empresaSelected != null) ? ((empresaSelected.trabajadoresSeleccionados.length>0) ? empresaSelected.trabajadoresSeleccionados : empresaSelected.trabajadores) : []"
            :mostrarPeriodo="mostrarPeriodo"
            :tipoDocumentoSelected="tipoDocumentoSelected"
            :mesSelected="mesSelected"
            :periodoSelected="periodoSelected"
            @generandoDocumento="generandoDoc"
            @mensajesGeneracion="getMensajesGeneracion"
            @obtenerTrabajadores="obtenerTrabajadores(empresaSelected)"
            @cerrarModal="cerrarModalTerminoContrato" />
        
        <ModalGeneracionContrato :mostrarModal="mostrarModalgeneracionContratos"
            @cerrarModal="cerrarModalGeneracionContrato"
            :trabajadores="ordenarTrabajadores()"
            :empresaSelected="empresaSelected"
            :mostrarPeriodo="mostrarPeriodo"
            :tipoDocumentoSelected="tipoDocumentoSelected"
            :mesSelected="mesSelected"
            :periodoSelected="periodoSelected"
            @generandoDocumento="generandoDoc"
            @obtenerTrabajadores="obtenerTrabajadores(empresaSelected)"
        />

        
    </div>
</template>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"

    import Multiselect from 'vue-multiselect'

    
    import { freeSet } from '@coreui/icons'
    import * as icons from '@coreui/icons'
    
    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'
    
    import CChartLineSimple from "./componentes/CChartLineSimple"
    
    import {TDropdown, } from 'vue-tailwind/dist/components'
    
    import FileUpload from "vue-upload-component";
    import apiUrls from '@/views/utils/apiUrls'
    
    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import GestionDocumentos from "./componentes/GestionDocumentos";
    
    import CargandoInfo from '@/views/utils/CargandoInfo'
    
    import ModalDocsView from "./componentes/ModalDocsView";
    
    import ModalSubirDocTrabajador from "./componentes/Modals/ModalSubirArchivoTrabajador";
    import ModalTerminoContrato from "./componentes/Modals/ModalTerminoContrato";
    import ModalGeneracionContrato from "./componentes/Modals/ModalGeneracionContrato.vue";

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import OpcionesFiltrosTrabajadores from "./componentes/filtros/OpcionesFiltrosTrabajadores";


    export default {
        name: 'ContratosFiniquitos',
        freeSet,
        icons,
        components: {
            Multiselect,
            CChartLineSimple,
            TDropdown,
            FileUpload,
            CargandoInfo,
            GestionDocumentos,
            ModalDocsView,
            'Modal': VueModal,
            DatePicker,
            OpcionesFiltrosTrabajadores,
            ModalSubirDocTrabajador,
            ModalTerminoContrato,
            ModalGeneracionContrato,
        },
        data () {
            return {
                mostrarSubirArchivos: false,
                
                tablaLista: true,
                isCollapsed: false,
                currentPage: 1,
                urlBase: cons.port+"://" + cons.ipServer,
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                
                isLoadingMes: false,
                isLoadingPeriodos: false,
                isLoadingTipoDocumentos: false,
                tipoDocumentoSelected: null,
                errorDctos: null,
                tipoDocumentos: [],
                    /*{ 
                        "valor": "contratos", 
                        "nombreDocumento": "Contratos", 
                        "permiteGenerarDoc": false, 
                        "permiteSubirDocs": true,
                        "tipoSubDocumentos": [
                            {
                                "valor": "contrato", 
                                "nombreDocumento": "Contrato", 
                            }, 
                            { 
                                "valor": "anexo_contrato", 
                                "nombreDocumento": "Anexo de Contrato", 
                            } 
                        ]
                    }, 
                    { 
                        "valor": "finiquitos", 
                        "nombreDocumento": "Finiquitos", 
                        "permiteGenerarDoc": true, 
                        "permiteSubirDocs": true,
                        "tipoSubDocumentos": [
                            {
                                "valor": "finiquito", 
                                "nombreDocumento": "Finiquito", 
                            }
                        ]
                    } 
                ],*/
                mostrarPeriodo: false,
                isLoadingEmpresasInfo: false,
                empresaSelected: null,
                empresasInfo: [],
                periodos: [],
                periodoSelected: null,
                mesSelected: null,

                isLoadingTrabajadores: false,
                isGenerando: false,

                rqstEmp: null,
                empresas: [],
                isLoadingEmpresas: false,
                paginacionEmpresas: { pageSize: 30, hideOnSinglePage: true },

                mostrarVisorPDF: false,
                empresaSelModal: null,
                trabajadorSelectedModal: {},
                documentoSelectedModal: null,
                documentosTrabajadorModal: [],

                //empresaSelModal: false,
                portContador: true,
                busquedaTrabajadores: "",
                filaSeleccionada: [],
                page: 1,
                max: 10,
                allCheck: false,

                envioDocs: false,
                trabEnviar: null,
                docsSeleccionadosEnviar: [],
                isEnviandoDocumentos: false,

                localeTrabajadores: {
                    emptyText: ' '
                },

                columnasTrabajadores: [
                    { title: 'Rut', scopedSlots:{ customRender: 'rutTrabajador' }, key: 'rutTrabajador', width: '20%', ellipsis: true},// width: '10%'
                    { title: 'Trabajador', dataIndex: 'nombreTrabajador', key: 'nombreTrabajador',  width: '30%', ellipsis: true},// width: '20%'
                    { title: 'Dosumentos', key: 'documento', scopedSlots: { customRender: 'documento' }, ellipsis: false, width: '50%', align: 'center'},// width: '10%'
                ],
                columnasArchivosContratos: [
                    { title: 'Tipo Dcto.', scopedSlots: { customRender: 'tipoDoc' }, key:"tipo", width: '10%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, key:"fechaEmision", width: '20%', align: 'center', ellipsis: false},
                    { title: 'Fecha Início Contrato', scopedSlots: { customRender: 'fechaContrato' }, key:"fechaContrato", width: '15%', align: 'left', ellipsis: false},
                    { title: 'Fecha Vencimiento', scopedSlots: { customRender: 'fechaVencimiento' }, key:"fechaVencimiento", width: '15%', align: 'center', ellipsis: false},
                    { title: 'Fecha Término', scopedSlots: { customRender: 'fechaTermino' }, key:"fechaTermino", width: '15%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, key:"documentos", width: '15%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, key:"estado", width: '10%', align: 'center', ellipsis: false},
                ],
                columnasArchivosFiniquitos: [
                    { title: 'Tipo Dcto.', scopedSlots: { customRender: 'tipoDoc' }, key:"tipo", width: '5%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, key:"fechaEmision", width: '10%', align: 'center', ellipsis: false},
                    { title: 'Fecha Término', scopedSlots: { customRender: 'fechaTermino' }, key:"fechaTermino", width: '10%', align: 'center', ellipsis: false},
                    { title: 'Fecha Início Contrato', scopedSlots: { customRender: 'fechaContrato' }, key:"fechaContrato", width: '10%', align: 'left', ellipsis: false},
                    { title: 'Causal', scopedSlots: { customRender: 'causalFiniquito' }, key:"causalFiniquito", width: '15%', align: 'center', ellipsis: false},
                    { title: 'Descripción Causal', scopedSlots: { customRender: 'descripcionFiniquito' }, key:"descripcionFiniquito", width: '15%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, key:"documentos", width: '20%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, key:"estado", width: '20%', align: 'center', ellipsis: false},
                    { title: 'Opciones', scopedSlots: { customRender: 'opciones' }, width: '15%', align: 'center', ellipsis: false},
                ],


                trabajadoresSeleccionados: [],
                mostrarModalGeneracion: false,
                generarPdf: false,

                mostrarMensajes: false,
                mensajesGeneracion: [],
                columnasMensajesGeneracion: [
                    { title: 'Mensajes Generación', scopedSlots: { customRender: 'mensajes' }, width: '70%', align: 'left'},
                ],

                mostrarTerminoContrato: false,
                mostrarTerminoContrato2: false,
                opcionSeleccionada: '',
                

                mostrarModalSubirDoc: false,
                linkSubirArchivoTrabajador: "",


                /**ya pasadas al modal */

                generarDocumento: false,
                /**ya pasadas al modal */

                loadingDocumentosTemplate: false,
                documentosTemplate: [],
                hayTemplates: false,
                templateSelected: null,

                mostrarModalgeneracionContratos: false,

                
            }
        },
        created: function(){},
        
        watch: { 
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.obtenerTiposDocumentos()
            //this.obtenerSoloInfoEmpresas()
            this.obtenerPeriodos()
            this.obtenerEmpresas()

        },
        mounted(){},
        beforeDestroy () {
            
            
        },
        methods: {
            abrirModalSubirDoc: function(documento, trabajador){
                this.mostrarModalSubirDoc = true
                this.linkSubirArchivoTrabajador = `${this.urlBase}${apiUrls.remuneraciones.contratoFiniquito.subirDocumentoTrabajador}${documento.idDocumentoCtro}/${this.tipoDocumentoSelected.nombreTipoDocumento}/${this.empresaSelected.idEmpresa}/${trabajador.idTrabajador}`
                
            },
            cerrarMostrarSubirDoc: function($event){
                if(!$event){
                    this.mostrarModalSubirDoc = false
                }
            },

            ordenarTrabajadores: function(){
                //(empresaSelected != null) ? ((empresaSelected.trabajadoresSeleccionados.length>0) ? empresaSelected.trabajadoresSeleccionados : empresaSelected.trabajadores) : []
                
                if(this.empresaSelected != null){
                    if(this.empresaSelected.trabajadoresSeleccionados.length > 0){
                        return this.empresaSelected.trabajadoresSeleccionados.slice().sort((a, b) => {
                            const aHasNullDoc = a.documentos.some(doc => doc.documento === null);
                            const bHasNullDoc = b.documentos.some(doc => doc.documento === null);
                            return aHasNullDoc && !bHasNullDoc ? -1 : (!aHasNullDoc && bHasNullDoc ? 1 : 0);
                        })
                    } else {
                        return this.empresaSelected.trabajadores.slice().sort((a, b) => {
                            const aHasNullDoc = a.documentos.some(doc => doc.documento === null);
                            const bHasNullDoc = b.documentos.some(doc => doc.documento === null);
                            return aHasNullDoc && !bHasNullDoc ? -1 : (!aHasNullDoc && bHasNullDoc ? 1 : 0);
                        })
                    }
                }
                return []
                
                /*return this.trabajadores.slice().sort((a, b) => {
                    const aHasNullDoc = a.documentos.some(doc => doc.documento === null);
                    const bHasNullDoc = b.documentos.some(doc => doc.documento === null);
                    return aHasNullDoc && !bHasNullDoc ? -1 : (!aHasNullDoc && bHasNullDoc ? 1 : 0);
                })*/

            },


            actualizarDocumento: function($event){
                this.empresaSelected.trabajadores.forEach(trabajador => {
                    if(trabajador.idTrabajador == $event.idTrabajador){
                        trabajador.documentos.forEach(doc =>{
                            if(doc.idDocumentoCtro == $event.idDocumento){
                                doc.documento = $event.documento
                                //doc.documento.idDocumento = $event.documento.idDocumento
                                //doc.documento.nombreDocumento = $event.documento.nombreDocumento
                                //doc.documento.ultimaGeneracion = $event.documento.ultimaGeneracion
                                //doc.documento.urlDocumento = $event.documento.urlDocumento
                                //doc.documento.viewDocumento = $event.documento.viewDocumento
                                //doc.documento.hayDocumento = $event.documento.hayDocumento
                                //doc.documento.estadoDocumento = $event.documento.estadoDocumento
                            }                            
                        })
                    }
                })
            },
            validarTrabajadoresSeleccionados: function(){
                let trabSeleccionados = []
                this.empresaSelected.trabajadoresSeleccionados.forEach(trabFilt =>{
                    let trabEncontrado = this.empresaSelected.trabajadores.find(trab => trab.idTrabajador == trabFilt.idTrabajador)
                    if(trabEncontrado){
                        trabSeleccionados.push(trabEncontrado)
                        trabEncontrado.seleccionado = true
                        trabFilt = trabEncontrado
                    } else{
                        this.empresaSelected.trabajadoresSeleccionados = this.empresaSelected.trabajadoresSeleccionados.filter(obj => obj.idTrabajador !== trabFilt.idTrabajador);
                    }
                    
                })
                this.empresaSelected.trabajadoresSeleccionados = trabSeleccionados
                this.allCheck = (this.empresaSelected.trabajadoresSeleccionados.length == this.empresaSelected.trabajadores.length)
                
            },
            seleccionarGenerarDocsTrabajador: function(trabajador){
                trabajador.seleccionado = false
                this.empresaSelected.trabajadoresSeleccionados = []
                this.seleccionarTrabajador(trabajador)
                //this.abrirModalGeneracionDocumentos()
                this.abrirModalGeneracionContrato()
            },
            mostrarGestionTemplates: function(){
                //this.mostrarSubirArchivos = true;}
                //this.$router.push({ path: 'templates_documentos', params: { plan: 'private' } })
                let data = {
                    "idEmpresa": this.empresaSelected.idEmpresa, 
                    "idDocSelec": this.tipoDocumentoSelected.idTipoDocumento,
                    "mostrarPeriodo": this.mostrarPeriodo
                }
                if(this.mostrarPeriodo){
                    data["anioPeriodo"] = (this.periodoSelected != null) ? this.periodoSelected.valor : null
                    data["mesPeriodo"] = (this.mesSelected != null) ? this.mesSelected.valor : null
                }
                
                let routeData = this.$router.resolve({path: 'templates_documentos', query: data});
                window.open(routeData.href, '_blank');
            },
            eliminarTerminoContrato: function(documento, trabajador){
                documento.isGenerando = true
                let url = this.urlBase +  apiUrls.remuneraciones.eliminarFiniquito;
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idFiniquito": documento.idDocumentoCtro,
                        "idEmpresa": this.empresaSelected.idEmpresa,
                        "idTrabajador": trabajador.idTrabajador,
                        "rutTrabajador": trabajador.rutTrabajador,
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    trabajador.documentos.forEach(doc => {
                        if(doc.idDocumentoCtro == documento.idDocumentoCtro)
                            trabajador.documentos.splice(trabajador.documentos.indexOf(doc), 1)
                    })
                    
                    trabajador.trabajadorFiniquito = false
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)

                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                })
                .finally(() => {
                    documento.isGenerando = false
                });
            },

            /* secciones Header */
            obtenerTiposDocumentos: function(){
                this.isLoadingTipoDocumentos = true

                var url =  this.urlBase + apiUrls.remuneraciones.contratoFiniquito.getTipoDocumentos//"/get_tipos_documentos/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.tipoDocumentos = result.data.tiposDocumentos
                    if(this.tipoDocumentos.length == 1){
                        this.tipoDocumentoSelected = this.tipoDocumentos[0]
                    }

                }, error => {
                    this.errorDctos = error.response.data
                })
                .finally(() => {
                    this.isLoadingTipoDocumentos = false
                });
            },
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = this.urlBase +  apiUrls.general.getEmpresasInfo + "/REMU";
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                    this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            obtenerPeriodos: function(){
                this.isLoadingPeriodos = true
                var url = this.urlBase + apiUrls.remuneraciones.getPeriodos

                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.periodos = result.data
                }, error => {
                    this.mensajeNotificacion = "Ups, Tuvimos un problema al obtener los periodos, si el problema persiste contacte con nuestro soporte, Gracias."
                })
                .finally(() => {
                    this.isLoadingPeriodos = false
                });
            },
            obtenerEmpresas: function(){

                if(this.rqstEmps != null){
                    this.rqstEmps.cancel()
                    this.rqstEmps.msg = "cancelado";
                    this.rqstEmps = null;
                    
                    this.isLoadingEmpresas = false
                }
                const axiosSource = axios.CancelToken.source();
                this.rqstEmps = { cancel: axiosSource.cancel, msg: "Cargando..." };
                this.empresas = []
                this.isLoadingEmpresas = true

                let url =  this.urlBase + apiUrls.general.getEmpresas

                axios({
                    method: "POST", 
                    "url": url,
                    cancelToken: axiosSource.token,
                    "data": {
                        "tipoBD": "REMU",
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                        "tipoDocumento": "contratos",
                        "empSelecteds": this.emFlt,
                        "paginacionEmpresas": {
                            "actual": this.paginacionEmpresas.current,
                            "tamanioPagina": this.paginacionEmpresas.pageSize
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.empresas = result.data.empresas

                    if(this.empresas.length<=0)
                        this.locale.emptyText = "No hay empresas para mostrar"

                    const pagination = { ...this.paginacionEmpresas };
                    pagination.total = result.data.totalEmpresas
                    this.paginacionEmpresas = pagination

                    this.isLoadingEmpresas = false
                    this.rqstEmp = null
                    if(result.data.mensajeGeneral != null){
                        this.mostrarNotificaciones(2, result.data.mensajeGeneral)
                    }
                }, 
                error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    this.isLoadingEmpresas = false
                    this.rqstEmp = null
                })
                .finally(() => {
                });

            },
            seleccionOpcionHeaders: function(){
                if(this.tipoDocumentoSelected != null && this.empresaSelected != null){
                    this.mensajesGeneracion = []
                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null && this.mesSelected != null){
                            this.empresaSelected.trabajadoresSeleccionados = []
                            this.allCheck = false
                            this.obtenerTrabajadores(this.empresaSelected)
                        }
                    } else{
                        this.empresaSelected.trabajadoresSeleccionados = []
                        this.allCheck = false
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                }
            },
            seleccionarEmpresa: function(){
                //this.empresaSelected = empresa
                this.seleccionOpcionHeaders()
                
                /*if(this.tipoDocumentoSelected != null){
                    
                    this.mensajesGeneracion = []

                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null || this.mesSelected != null){
                            this.obtenerTrabajadores(this.empresaSelected)
                            this.empresaSelected.trabajadoresSeleccionados = []              
                        }
                    } else{
                        this.obtenerTrabajadores(this.empresaSelected)
                        this.empresaSelected.trabajadoresSeleccionados = []        
                    }
                }*/

            },
            seleccionarMostraPeriodo: function(){
                this.mostrarPeriodo = !this.mostrarPeriodo
                this.seleccionOpcionHeaders()
                /*if(this.mostrarPeriodo){
                    if(this.tipoDocumentoSelected != null && this.empresaSelected != null && this.mesSelected!=null && this.periodoSelected != null){
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                } else{
                    if(this.tipoDocumentoSelected != null && this.empresaSelected != null){
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                }*/
            },
            
            /*seleccionarTipoDocumento: function(){
                if(this.empresaSelected != null){
                    this.mensajesGeneracion = []
                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null || this.mesSelected != null){
                            this.obtenerTrabajadores(this.empresaSelected)
                        }
                    } else{
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                }
            },
            seleccionarAnioPeriodo: function(){
                if(this.empresaSelected != null){
                    this.mensajesGeneracion = []
                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null || this.mesSelected != null){
                            this.obtenerTrabajadores(this.empresaSelected)
                        }
                    } else{
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                }
            },
            seleccionarMeses: function(){
                if(this.empresaSelected != null){
                    
                    this.mensajesGeneracion = []
                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null || this.mesSelected != null){
                            this.obtenerTrabajadores(this.empresaSelected)
                        }
                    } else{
                        this.obtenerTrabajadores(this.empresaSelected)
                    }
                }
            },*/
            
            /*actualizarInformacion: function(){
                
                if(this.tipoDocumentoSelected != null){
                    if(this.mostrarPeriodo){
                        if(this.periodoSelected != null || this.mesSelected != null){
                            this.obtenerTrabajadores(this.empresaSelected)
                        this.empresaSelected.trabajadoresSeleccionados = []    
                        }
                    } else{
                        this.obtenerTrabajadores(this.empresaSelected)
                        this.empresaSelected.trabajadoresSeleccionados = []    
                    }
                }

            },*/
            
            /* Fin secciones Header */

            /* Gestion Modals */            
            abrirModalGeneracionDocumentos: function(){
                this.mostrarModalGeneracion = true
                this.getDocumentosTemplate()
            },
            cerrarModalGeneracion: function(){
                this.mostrarModalGeneracion = false
            },
            abrirModalTerminoContrato: function(){
                //this.getDocumentosTemplate()
                this.mostrarTerminoContrato = true
            },
            
            cerrarModalTerminoContrato: function(){
                this.mostrarTerminoContrato = false
            },
            abrirModalGeneracionContrato: function(){
                //this.getDocumentosTemplate()
                this.mostrarModalgeneracionContratos = true
            },
            cerrarModalGeneracionContrato: function(){
                this.mostrarModalgeneracionContratos = false
            },

            generandoDoc: function(valorGenerando){
                this.isGenerando = valorGenerando
            },

            verRespuestaGeneracion: function(){
                let mensaje = ""
                this.mensajesGeneracion.forEach(msj => {
                    mensaje += `<div class="mt-1" style="font-size:11px">* ${msj}</div>`
                })
                return mensaje
            },
            getMensajesGeneracion: function(mensajes){
                this.mensajesGeneracion = mensajes
            },
            

            /* Fin Gestion Modals */
            
            eliminarDocumento: function(empresa, trabajador, documento){

                let url = `${this.urlBase}${apiUrls.remuneraciones.eliminarDocTrabajador}`

                documento.documento.isLoadingDoc = true

                axios({
                    method: "POST",
                    "url": url,
                    "data": {
                        "tipoBd": "REMU",
                        "idEmpresa": empresa.idEmpresa,
                        "idDocumento": documento.documento.idDocumento,
                        "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : null,
                        "filtrarPorPeriodo": this.mostrarPeriodo
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    documento.documento = null
                    let hayDocumentos = false
                    trabajador.documentos.forEach(doc => {
                        if(doc.documento != null){
                            hayDocumentos = true
                        }
                    })
                    trabajador.hayDocumentos = hayDocumentos
                    this.mostrarNotificaciones(1, `${result.data.mensajeGeneral}`)

                },(error) => {
                        this.mostrarNotificaciones(3, `${error.response.data.mensajeGeneral}`)
                    }
                )
                .finally(() => {
                    if(documento.documento != null){
                        documento.documento.isLoadingDoc = false
                    }
                });

            },

            obtenerTrabajadores: function(empresa){

                this.isLoadingTrabajadores = true
                //let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`
                let url = this.urlBase + apiUrls.general.getTrabajadoresEmpresaTipoDoc
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "idEmpresa": this.empresaSelected.idEmpresa,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : 'null',
                        "mes": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                        "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : 'null',
                        "paginacionTrabajadores": {
                            "paginacion": false,
                            "actual": this.empresaSelected.paginacion.current,
                            "tamanioPagina": this.empresaSelected.paginacion.pageSize,
                            "filtrosTrabajadores": this.empresaSelected.trabajadoresFiltrados,
                            "filtros": this.empresaSelected.opcionSeleccionadaFiltro
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.empresaSelected.trabajadores = result.data.trabajadores
                    this.empresaSelected.totalTrabajadoresDocumentos = result.data.totalDocumentosTrab
                    this.empresaSelected.totalTrabajadores = result.data.totalTrabajadores
                    
                    if(this.empresaSelected.totalTrabajadoresDocumentos >0){
                        this.empresaSelected.hayDocumentosTrabajador = true
                    }

                    const pagination = { ...this.empresaSelected.paginacion };
                    pagination.total = result.data.totalTrabajadores
                    pagination.empresa = this.empresaSelected
                    this.empresaSelected.paginacion = pagination

                    this.validarTrabajadoresSeleccionados()
                    

                }, error => {
                    this.mensajeNotificacion = "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias."
                })
                .finally(() => {
                    this.isLoadingTrabajadores = false
                });
                
            },
            
            seleccionarTrabajador: function(trabajador){
                
                let trabEncontradoIndex = this.empresaSelected.trabajadoresSeleccionados.findIndex(trab => trab.idTrabajador === trabajador.idTrabajador);

                if (trabEncontradoIndex !== -1) {
                    trabajador.seleccionado = false;
                    this.empresaSelected.trabajadoresSeleccionados.splice(trabEncontradoIndex, 1);
                } else {
                    trabajador.seleccionado = true;
                    this.empresaSelected.trabajadoresSeleccionados.push(trabajador);
                }

                this.allCheck = (this.empresaSelected.trabajadoresSeleccionados.length == this.empresaSelected.trabajadores.length)
                
            },  
            seleccionarTodos: function(){
                if(!this.allCheck){
                    this.empresaSelected.trabajadores.forEach(trab =>{
                        let trabEncontrado = this.empresaSelected.trabajadoresSeleccionados.filter(objeto => objeto.idTrabajador == trab.idTrabajador)
                        if(trabEncontrado.length==0){
                            this.seleccionarTrabajador(trab)
                        }
                    })
                } else{
                    this.empresaSelected.trabajadores.forEach(trab =>{
                        this.seleccionarTrabajador(trab)
                    })
                }
            },
            seleccionarTrabajadores: function(empresa, trabajador, documento){
                console.log("documento", documento, trabajador.documentos.length)
                let doc = null
                if(documento == null && trabajador.documentos.length > 0){
                    trabajador.documentos.forEach(docu => {
                        console.log("doc", docu)
                        if(docu.documento != null){
                            doc = docu.documento
                            return
                        }
                    })
                } else {
                    doc = documento
                }
                console.log("docdoc", doc)
                this.empresaSelModal = empresa
                this.trabajadorSelectedModal = trabajador
                this.documentoSelectedModal = doc
                this.documentosTrabajadorModal = trabajador.documentos

                this.mostrarVisorPDF = true
            },
            getColorEstadoDocumento: function(documento){
                switch(documento.estadoDocumento) {
                    case 1: return "info"
                    case 2: return "danger"
                    case 3: return "success"
                    default: return "secondary"
                }

            },
            getEstadoDocumento: function(documento){
                if(documento.hayDocumento){
                    switch(documento.estadoDocumento) {
                        case 1: return "En Revisión"
                        case 2: return "Rechazado"
                        case 3: return "Aceptado"
                        default: return "Sin Enviar"
                    }
                } else{
                    return "Sin Documento"
                }
            },
            seleccionFiltro: function(evento){
                this.empresaSelected.opcionSeleccionadaFiltro = evento
                this.obtenerTrabajadores(this.empresaSelected)
            },

            getDocumentosTemplate: function(){
                if(this.tipoDocumentoSelected != null && this.empresaSelected != null){
                    this.loadingDocumentosTemplate = true
                    let url =  this.urlBase + "/get_documentos_template/"
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "idTipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.idTipoDocumento : 'null',
                            "mes": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : null
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        this.documentosTemplate = result.data.templateDocs
                        if(this.documentosTemplate.length>0){
                            this.hayTemplates = true
                        }
                        if(this.documentosTemplate.length==1){
                            this.templateSelected = this.documentosTemplate[0]
                        }
                        this.loadingDocumentosTemplate = false
                        
                    }, error => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        this.loadingDocumentosTemplate = false
                    })
                    .finally(() => {
                    });
                }
            },

        }
        
    }
</script>

<style>

</style>