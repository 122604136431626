<template>
    <div>
        <Modal :enable-close="false"
            v-model="mostrarModal"
            :title="'Término de contrato'"
            :modal-style="{ 'min-width': '300px', 'max-width': '900px'}"            
            @before-open="abrirModal"
            @close="cerrarModal">
            <div>
                <CRow>
                    <CCol>
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="isCausalGeneral"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="seleccionarTerminoGeneral"
                        /> Termino contrato general
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="generarDocumento"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="generarDocumento = !generarDocumento"
                        /> Generar con Documento
                    </CCol>
                    <CCol v-if="generarDocumento && hayTemplates && !isCausalGeneral">
                        <CSwitch
                            width="150px"
                            class="mx-1 align-middle mb-2"
                            shape="pill"
                            variant="outline"
                            :checked="utilizarTemplateGeneral"
                            v-bind="{labelOn: 'Si',labelOff: 'No'}"
                            @update:checked="seleccinarTemplateGeneral"
                        /> Template general
                    </CCol>
                    <CCol v-if="generarDocumento && hayTemplates">
                        <multiselect
                            v-show="utilizarTemplateGeneral && !isCausalGeneral"
                            class="multSelectGesto mb-2"
                            v-model="templateSelected"
                            selectedLabel=""
                            deselectLabel=""
                            selectLabel=""
                            placeholder="Seleccionar Template" 
                            label="nombreDocumento" 
                            track-by="nombreDocumento"
                            :loading="loadingDocumentosTemplate"
                            :options="documentosTemplate"
                            :multiple="false">
                        </multiselect>
                    </CCol>
                </CRow>
                <CRow v-show="empresaSelected != null">
                    <CCol>
                        <div>
                            <CRow v-show="!hayTemplates">
                                <CCol>
                                    <span style="color: #0b7689;">* No hay templates para utilizar, se generarán con un template predefinido, si desea agregar un template Ingrese aqui <<<a @click="irGestionarTemplate" style="font-size: 14px;">Gestionar Templates</a>>></span>
                                </CCol>
                            </CRow>
                        </div>
                        <div v-if="isCausalGeneral">
                            <CRow>
                                <CCol>
                                    <span class="mb-2" style="font-size: 14px; color: #0a5c6b;">* Se asignará para todos los trabajadores esta causal</span>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <multiselect
                                        :disabled="validarTerminoContrato()==1"
                                        class="multSelectGesto mb-2"
                                        v-model="causalSelected"
                                        :custom-label="customCausales" 
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                        placeholder="Seleccionar Causal" 
                                        label="descripcionCausal" 
                                        track-by="articuloCausal" 
                                        :loading="causalesFnq.isLoading"
                                        :options="causalesFnq.causales" 
                                        :multiple="false"
                                        @input="seleccionarCausalFechaGeneral('causal')">
                                    </multiselect>
                                </CCol>
                                <CCol>
                                    <date-picker
                                        :disabled="validarTerminoContrato()==1"
                                        style="width: 100%;"
                                        :editable="false"
                                        :clearable="false"
                                        default-panel="date"
                                        type="date"
                                        class="mb-2"
                                        :lang="langFecha" 
                                        v-model="fechaTerminoGeneral"
                                        :confirm="false"
                                        @change="seleccionarCausalFechaGeneral('fecha')"></date-picker>
                                </CCol>
                                <CCol v-if="generarDocumento && hayTemplates">
                                    <multiselect
                                        :disabled="validarTerminoContrato()==1"
                                        v-show="utilizarTemplateGeneral"
                                        class="multSelectGesto mb-2"
                                        v-model="templateSelected"
                                        selectedLabel=""
                                        deselectLabel=""
                                        selectLabel=""
                                        placeholder="Seleccionar Template" 
                                        label="nombreDocumento" 
                                        track-by="nombreDocumento" 
                                        :loading="loadingDocumentosTemplate"
                                        :options="documentosTemplate" 
                                        :multiple="false">
                                    </multiselect>
                                </CCol>
                            </CRow>
                            <CRow v-show="validarTerminoContrato() == 1">
                                <CCol>
                                    <span style="color: #0b7689;">* Los Trabajadores seleccionados ya tienen término de contrato</span>
                                </CCol>
                            </CRow>
                            <CRow v-show="validarTerminoContrato() == 2">
                                <CCol>
                                    <span style="color: #0b7689;">* Hay uno o más trabajadores con término de contrato, solo se creará a aquellos que no tengan</span>
                                    <a style="text-decoration: none; color:#0b7689" class="ml-1"
                                        :content="verMensaje()"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : false,
                                            interactive : true,
                                        }">
                                        <!--<CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />-->
                                        <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                            
                                    </a>
                                </CCol>
                            </CRow>
                            <CRow v-show="validarTerminoContrato() == 2">
                                <CCol>
                                    <span style="color: #0b7689;">* Trabajadores seleccionados que se les hará Término de contrato: {{revisarTrabajadores()}}</span>
                                </CCol>
                            </CRow>
                            
                        </div>
                        <CRow v-else>
                            <CCol>
                                <div class="mb-2" style="font-size: 14px; color: #0b7689;">
                                    *si no asigna una causal el trabajador no se realizará termino de contrato
                                </div>
                                <div v-if="empresaSelected != null && trabajadores.length>0">
                                    <div :key="index" v-for="(trab, index) in trabajadores">
                                        <CRow>
                                            <CCol col="4">
                                                <span class="font-weight-bold" style="font-size: 12px;">
                                                    ({{ $utils.formatearRut(trab.rutTrabajador) }}) {{ trab.nombreTrabajador }}
                                                </span>
                                            </CCol>
                                            <CCol v-show="trab.trabajadorFiniquito">
                                                <span style="color: #0b7689;">*Trabajador ya tiene Término de contrato</span>
                                                <span style="color: #0b7689;" v-show="trab.hayDocumentos"> y documento</span>
                                            </CCol>
                                            <CCol v-show="trab.trabajadorFiniquito && !trab.hayDocumentos && generarDocumento">
                                                <CSwitch
                                                    width="150px"
                                                    class="mx-1 align-middle mb-2"
                                                    shape="pill"
                                                    variant="outline"
                                                    :checked="trab.generarDocumento"
                                                    v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                                    @update:checked="trab.generarDocumento = !trab.generarDocumento"
                                                />  Generar Documento
                                            </CCol>
                                            <CCol v-show="trab.trabajadorFiniquito && trab.generarDocumento && !utilizarTemplateGeneral">
                                                <multiselect
                                                    class="multSelectGesto mb-2"
                                                    v-model="trab.template"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    selectLabel=""
                                                    placeholder="Template" 
                                                    label="nombreDocumento" 
                                                    track-by="nombreDocumento" 
                                                    :options="documentosTemplate" 
                                                    :multiple="false">
                                                </multiselect>
                                            </CCol>

                                            <CCol v-show="!trab.trabajadorFiniquito">
                                                <multiselect
                                                    class="multSelectGesto mb-2"
                                                    v-model="trab.causal"
                                                    :custom-label="customCausales" 
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    selectLabel=""
                                                    placeholder="Seleccionar Causal" 
                                                    label="descripcionCausal" 
                                                    track-by="articuloCausal" 
                                                    :loading="causalesFnq.isLoading"
                                                    :options="causalesFnq.causales" 
                                                    :multiple="false">
                                                </multiselect>
                                            </CCol>
                                            <CCol v-show="!trab.trabajadorFiniquito">
                                                <date-picker
                                                    style="width: 100%;"
                                                    :editable="false"
                                                    :clearable="false"
                                                    default-panel="date"
                                                    type="date"
                                                    class="mb-2"
                                                    :lang="langFecha" 
                                                    v-model="trab.fechaTermino"
                                                    :confirm="false"></date-picker>
                                            </CCol>
                                            <CCol v-if="generarDocumento && hayTemplates" v-show="!trab.trabajadorFiniquito && !utilizarTemplateGeneral">
                                                <multiselect
                                                    class="multSelectGesto mb-2"
                                                    v-model="trab.template"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    selectLabel=""
                                                    placeholder="Template" 
                                                    label="nombreDocumento" 
                                                    track-by="nombreDocumento" 
                                                    :options="documentosTemplate" 
                                                    :multiple="false">
                                                </multiselect>
                                            </CCol>
                                        </CRow>
                                        <hr class="hr hr-blurry" />
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
            </div>
            
            <div class="vm-footer">
                <CRow>
                    <CCol>
                    <CButton
                        :disabled="isGenerando"
                        class="btnPrincipalVapp float-left"
                        @click="generarTerminoContrato()"
                    >
                        <CIcon v-if="isGenerando" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                        Generar término contrato
                    </CButton>
                </CCol>
                    <CCol>
                        <CButton
                            class="btnPrincipalVapp float-right"
                            @click="cerrarModal"
                        >Cerrar</CButton>
                    </CCol>
                </CRow>
            </div>
        </Modal>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'

    import { freeSet } from "@coreui/icons";
    import Multiselect from 'vue-multiselect'
    
    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import DatePicker from 'vue2-datepicker';

    export default {
        name: "ModalSubirDocTrabajador",
        freeSet,
        props: {
            mostrarModal: Boolean,
            empresaSelected: Object,
            trabajadores: Array,
            //causalesFnq: Object,
            mostrarPeriodo: Boolean,
            tipoDocumentoSelected: Object,
            mesSelected: Object,
            periodoSelected: Object


        },
        components: {
            'Modal': VueModal,
            Multiselect,
            DatePicker,
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                
                langFecha: cons.langPicker,
                
                isGenerando: false,

                isCausalGeneral: true,
                causalGeneral: "",
                fechaTerminoGeneral: null,
                causalSelected: null,
                causalesFnq: {
                    "isLoading": false,
                    "causales": []
                },

                loadingDocumentosTemplate: false,
                generarDocumento: false,
                hayTemplates: false,
                documentosTemplate: [],
                templateSelected: null,

                utilizarTemplateGeneral: true,

            };
        },
        created: function () {},
        beforeMount() {},
        methods: {
            cerrarModal: function(){
                //this.mostrarModalSubirDoc = false
                this.archivosSubirTrabajador = []
                this.$emit('cerrarModal', false)

            },
            abrirModal: function(){
                this.getDocumentosTemplate()
                this.getCausalesFiniquitos()
            },
            getDocumentosTemplate: function(){
                if(this.tipoDocumentoSelected != null && this.empresaSelected != null){
                    this.loadingDocumentosTemplate = true
                    let url =  this.urlBase + apiUrls.remuneraciones.contratoFiniquito.getDocumentosTemplate
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "idEmpresa": this.empresaSelected.idEmpresa,
                            "idTipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.idTipoDocumento : 'null',
                            "mes": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : null
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        this.documentosTemplate = result.data.templateDocs
                        if(this.documentosTemplate.length>0){
                            this.hayTemplates = true
                        }
                        if(this.documentosTemplate.length==1){
                            this.templateSelected = this.documentosTemplate[0]
                        }
                        this.loadingDocumentosTemplate = false
                        
                    }, error => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        this.loadingDocumentosTemplate = false
                    })
                    .finally(() => {
                    });
                }
            },

            seleccionarTerminoGeneral: function(){
                this.isCausalGeneral = !this.isCausalGeneral
                if(this.isCausalGeneral)
                    this.utilizarTemplateGeneral = true
            },

            seleccinarTemplateGeneral: function(){
                this.utilizarTemplateGeneral = !this.utilizarTemplateGeneral
            },
            getCausalesFiniquitos: function(){
                this.causalesFnq.isLoading = true

                let url = this.urlBase + apiUrls.remuneraciones.contratoFiniquito.getCausalesFiniquito
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idEmpresa": this.empresaSelected.idEmpresa,
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.causalesFnq.causales = result.data.causales
                }, error => {
                    this.causalesFnq.isLoading = false
                })
                .finally(() => {
                    this.causalesFnq.isLoading = false
                });

            },
            
            customCausales: function(causal) {
                return `${causal.descripcionCausal} (${causal.articuloCausal})`
            },

            validarTerminoContrato: function(){
                let hayConTermino = false
                let totalConTermino = 0
                if(this.empresaSelected != null){
                    //if(this.trabajadoress.length>0){
                    this.trabajadores.forEach(trabajador => {
                        if(trabajador.trabajadorFiniquito){
                            hayConTermino = true
                            totalConTermino += 1
                        }
                    })
                    /*} else{
                        this.empresaSelected.trabajadores.forEach(trabajador => {
                            if(trabajador.trabajadorFiniquito){
                                hayConTermino = true
                                totalConTermino += 1
                            }
                        })
                    }*/
                    
                    if(totalConTermino == this.trabajadores.length){
                        return 1
                    }
                    else if(totalConTermino > 0){
                        return 2
                    }
                }
            },
            seleccionarCausalFechaGeneral: function(tipo){
                //if(this.trabajadores.length>0){
                this.trabajadores.forEach(trabajador => {
                    if(tipo == "causal"){
                        trabajador.causal = this.causalSelected
                    }
                    if(tipo == "fecha"){
                        trabajador.fechaTermino = this.fechaTerminoGeneral
                    }
                })
                
            },
            revisarTrabajadores: function(){
                if(this.empresaSelected != null){
                    let mensajes = []
                    this.trabajadores.forEach(trabajador => {
                        if(!trabajador.trabajadorFiniquito){
                            mensajes.push(`${trabajador.rutTrabajador}`)
                        }
                    })

                    return  `(${mensajes.join(", ")})`
                }
            },
            verMensaje: function(){
                if(this.empresaSelected != null){
                    
                    let mensaje = "Trabajador(es)"
                    let mensajes = []
                    let total = 0
                    this.trabajadores.forEach(trabajador => {
                        if(trabajador.trabajadorFiniquito){
                            if(total<5){
                                mensajes.push(`${trabajador.rutTrabajador}`)
                            }
                            total += 1
                        }
                    })
                    let mensajeMas = total>5?` y otros ${total-mensajes.length}` : ''

                    mensaje += `<div>${mensajes.join(", ")}${mensajeMas}</div> ya tienen término de contrato`//" ya tienen término de contrato"
                    
                    return mensaje
                }
            },
            validarOpcionesSelecionadas: function(trabajador, mensajeTrb){
                if(!trabajador.trabajadorFiniquito){
                    if(trabajador.causal == null){
                        mensajeTrb.push(`Causal`)
                    }
                    if(trabajador.fechaTermino == null){
                        mensajeTrb.push(`fecha de término`)
                    }
                }
                if(!this.utilizarTemplateGeneral && this.hayTemplates){
                    if(trabajador.template == null){
                        mensajeTrb.push(`Template`)
                    }
                }
            },

            generarTerminoContrato: function(){

                this.isGenerando = true
                this.$emit('generandoDocumento', true)
                let url = `${this.urlBase}${apiUrls.remuneraciones.contratoFiniquito.generarTerminoCtroTmpl}`

                let idsTrabSeleccionados = []
                let permiteGenerar = true

                /** muestra el logo de carga por trabajador */
                this.trabajadores.forEach(trab =>{
                    if(trab.seleccionado){
                        trab.isLoadingGenerarDocumento = true
                    }
                })

                /** Validamos las opciones seleccionadas */
                if(this.isCausalGeneral){
                    if(this.hayTemplates){
                        if(this.causalSelected == null || this.fechaTerminoGeneral == null || this.templateSelected == null){
                            this.mostrarNotificaciones(2, `No ha seleccionado una causal, fecha o template`)
                            permiteGenerar = false
                        }
                    }
                    else{
                        if(this.causalSelected == null || this.fechaTerminoGeneral == null){
                            this.mostrarNotificaciones(2, `No ha seleccionado una causal o fecha`)
                            permiteGenerar = false
                        }
                    }
                }
                else{
                    if(this.trabajadores.length>0){
                        let mensajes = []
                        this.trabajadores.forEach(trabajador => {
                            let mensajeTrb = []
                            if(!trabajador.trabajadorFiniquito){
                                this.validarOpcionesSelecionadas(trabajador, mensajeTrb)
                            }
                            else{
                                if(trabajador.generarDocumento){
                                    this.validarOpcionesSelecionadas(trabajador, mensajeTrb)
                                }                                
                            }
                            if(mensajeTrb.length>0){
                                mensajes.push(`no se han seleccionado opciones necesarias para trabajador ${trabajador.rutTrabajador} (${mensajeTrb.join(", ")})  sin seleccionar`)
                                permiteGenerar = false
                            }
                        })
                        if(mensajes.length>0){
                            this.mostrarNotificaciones(2, mensajes.join(", "))
                        }
                    }
                }

                if(!permiteGenerar){
                    this.isGenerando = false
                    this.$emit('generandoDocumento', false)
                    
                    this.trabajadores.forEach(trab =>{                                                                                          
                        if(trab.seleccionado){
                            trab.isLoadingGenerarDocumento = false
                        }
                    })
                    return
                }
                
                if(this.trabajadores.length>0){
                    this.trabajadores.forEach(trabajador => {
                        let idTemplate = null
                        let fechaTermino = null
                        if(trabajador.template != null){
                            idTemplate = trabajador.template.idTemplateDocumento
                        }
                        if(!this.isCausalGeneral){
                            if(trabajador.fechaTermino != null){
                                fechaTermino = format(trabajador.fechaTermino, "yyyy-MM-dd", {locale: es})
                            }
                        }
                        let generarDoc = false
                        if(this.generarDocumento){
                            if(!trabajador.trabajadorFiniquito){
                                generarDoc = true
                            } else{
                                generarDoc = trabajador.generarDocumento
                            }
                        }
                        idsTrabSeleccionados.push({
                            "idTrabajador": trabajador.idTrabajador, 
                            "rutTrabajador": trabajador.rutTrabajador, 
                            "causal": trabajador.causal, 
                            "fechaTermino": fechaTermino, 
                            "template": idTemplate, 
                            "generarDocumento": generarDoc, 
                            "tieneFiniquito": trabajador.trabajadorFiniquito, 
                            "tieneDocumento": trabajador.hayDocumentos
                        })
                    });
                }

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idEmpresa": this.empresaSelected.idEmpresa,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.nombreTipoDocumento : 'null',
                        "mes": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                        "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : 'null',
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "trabajadoresSeleccionados": idsTrabSeleccionados,
                        "isCausalGeneral": this.isCausalGeneral,
                        "causalSelected": this.causalSelected,
                        "fechaTerminoGeneral": (this.fechaTerminoGeneral != null)?format(this.fechaTerminoGeneral, "yyyy-MM-dd", {locale: es}):null,
                        "generarDocumento": this.generarDocumento,
                        "hayTemplates": this.hayTemplates,
                        "utilizarTemplateGeneral": this.utilizarTemplateGeneral,
                        "templateSeleccionado": (this.hayTemplates ? ((this.utilizarTemplateGeneral) ? this.templateSelected.idTemplateDocumento : null) : null)
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isGenerando = false
                    this.$emit('generandoDocumento', false)
                    this.$emit('mensajesGeneracion', result.data.mensajes)
                    //this.mensajesGeneracion = result.data.mensajes
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    //this.obtenerTrabajadores(this.empresaSelected)
                    
                    this.$emit('obtenerTrabajadores')
                    
                    this.cerrarModal()
                    /*if(this.mostrarTerminoContrato){
                        this.mostrarTerminoContrato = false
                    }
                    if(this.mostrarModalGeneracion){
                        this.mostrarModalGeneracion = false
                    }*/
                }, 
                error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    this.isGenerando = false
                    this.$emit('generandoDocumento', false)
                    this.cerrarModal()
                })
                .finally(() => {
                    this.isGenerando = false
                    this.$emit('generandoDocumento', false)
                    this.trabajadores.forEach(trab =>{
                        if(trab.seleccionado){
                            trab.isLoadingGenerarDocumento = false
                        }
                    })
                });

            },

            irGestionarTemplate: function(){

                let data = {
                    "idEmpresa": this.empresaSelected.idEmpresa, 
                    "idDocSelec": this.tipoDocumentoSelected.idTipoDocumento,
                    "mostrarPeriodo": this.mostrarPeriodo
                }
                if(this.mostrarPeriodo){
                    data["anioPeriodo"] = (this.periodoSelected != null) ? this.periodoSelected.valor : null
                    data["mesPeriodo"] = (this.mesSelected != null) ? this.mesSelected.valor : null
                }
                
                let routeData = this.$router.resolve({path: 'templates_documentos', query: data});
                window.open(routeData.href, '_blank');
            },
            

        },
    };
</script>