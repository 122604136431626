<template>
    <div>
        <Modal :enable-close="false"
            v-model="mostrarModal"
            :title="'Subir Documento'"
            :modal-style="{ 'min-width': '300px', 'max-width': '900px'}"
            @close="cerrarMostrarSubirDoc">

            <!--<span class="pi pi-file-pdf" style="font-size: 1.3rem; color:#0a5c6b"></span>
            <span class="pi pi-file-word" style="font-size: 1.3rem; color:#0a5c6b"></span>
            <span v-for="archivo in archivosSubirTrabajador" :key="archivo.id">
                <div>
                    <span>Nombre: </span>
                    <span>{{archivo.name}}</span>
                </div>
                <div>
                    <span>Tamaño: </span>
                    <span>{{archivo.size | prettyBytes}}</span>
                </div>
                <div>
                    <span>Velocidad: </span>
                    <span>{{ archivo.speed | prettyBytes }}/s</span>
                </div>
                <div>
                    <span>Progreso: 
                        <CProgress
                            style="width: 50%" 
                            :value="parseFloat(archivo.progress)"
                            :precision="2" 
                            show-percentage 
                            :animated="true"
                            :color="(archivo.error)?'danger':'success'"
                        />
                    </span>
                </div>
                <div>
                    <CButton
                        title="Subir archivo"
                        color="success" 
                        size="sm"
                        @click.prevent="archivo.success || archivo.error === 'compressing'? false: $refs.archivoTrabajador.update(archivo, { active: true })">
                        <CIcon :content="$options.freeSet.cilCloudUpload"/>
                    </CButton>
                    |
                    <CButton
                        v-show="archivosSubirTrabajador.length>0 && !isLoadingSubir"
                        title="Cancelar subida archivo"
                        class="btn-danger"
                        size="sm"
                        @click.prevent="archivosSubirTrabajador = []">
                        <CIcon :content="$options.freeSet.cilBan"/>
                        Quitar Documentos
                    </CButton>
                    |
                    <CButton
                        v-show="!parseFloat(archivo.progress) == 100"
                        title="Cancelar subida archivo"
                        class="btn-danger"
                        size="sm"
                        v-if="!$refs.archivoTrabajador || !$refs.archivoTrabajador.active"
                        @click.prevent="$refs.archivoTrabajador.active = false">
                        <CIcon :content="$options.freeSet.cilXCircle"/>
                        Detener
                    </CButton>
                </div>
            </span>-->
            <CRow>
                <CCol><file-upload
                class="btn btnPrincipalVapp mt-2"
                :post-action="linkSubirArchivoTrabajador"
                :headers="headers"
                :extensions="''"
                :accept="''"
                :multiple="false"
                :directory="false"
                :size="0"
                :thread="3"
                :drop="false"
                :drop-directory="false"
                :add-index="false"
                v-model="archivosSubirTrabajador"
                @input-file="inputFile"
                ref="archivoTrabajador">
                <!--<CIcon :content="$options.freeSet.cilPlus" />-->
                <span class="pi pi-plus" style="font-size: 0.8rem; color:#0a5c6b"></span>
                Seleccionar Archivo
            </file-upload>
            </CCol>
        
            </CRow>
            <div>
                <a-table
                    row-key="id"
                    :columns="columnasSubidaArchivoTrabajador" 
                    :data-source="archivosSubirTrabajador"
                    class="tableInterior mt-2" 
                    size="small">
                    <span slot="tipoArchivo" slot-scope="archivo">
                        <CRow>
                            <CCol>
                                <span v-if="validarTipoArchivo(archivo.name)==2" class="pi pi-file-pdf" style="font-size: 1.3rem; color:#0a5c6b"></span>
                                <span v-if="validarTipoArchivo(archivo.name)==1" class="pi pi-file-word" style="font-size: 1.3rem; color:#0a5c6b"></span>
                                <span v-if="validarTipoArchivo(archivo.name)==3" class="pi pi-file" style="font-size: 1.3rem; color:#0a5c6b"></span>
                                
                            </CCol>
                        </CRow>
                    </span>
                    <span slot="nombreArchivo" slot-scope="archivo">
                        <CRow>
                            <CCol>
                                <!--({{validarTipoArchivo(archivo.name) }}) -->
                                
                                {{archivo.name}}
                            </CCol>
                        </CRow>
                    </span>
                    <template slot="progreso" slot-scope="archivo">
                        <CRow>
                            <CCol>
                                <CProgress 
                                :value="parseFloat(archivo.progress)"
                                :precision="2" 
                                show-percentage 
                                :animated="true"
                                :color="(archivo.error)?'danger':'success'"
                                />
                            </CCol>
                        </CRow>
                    </template>
                    <template slot="tamanio" slot-scope="archivo">
                        {{archivo.size | prettyBytes}}
                    </template>

                    <template slot="velocidad" slot-scope="archivo">
                        {{ archivo.speed | prettyBytes }}/s
                    </template>

                    <template slot="estado" slot-scope="archivo">
                        <span v-if="archivo.error" style="color:#7E1146">
                            <span v-if="archivo.error === 'size'">
                                Archivo demasiado pequeño
                            </span>
                            <span v-else>{{ archivo.response.mensajeGeneral }}</span>
                        </span>

                        <span v-else-if="archivo.success" style="color: #2eb85c">
                            {{archivo.response.mensajeGeneral}}

                        </span>
                        <CBadge v-else-if="archivo.active" color="warning" >
                            Subiendo
                        </CBadge>
                        <CBadge v-else color="secondary" >
                            En espera
                        </CBadge>
                    </template>

                    <template slot="acciones" slot-scope="archivo">
                        <CButtonGroup class="mx-1">
                            <CButton
                                v-if="archivo.active" 
                                content="Cancelar Subida"
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                title="Cancelar subida archivo"
                                color="danger" 
                                size="sm"
                                @click.prevent="archivo.active? $refs.archivoTrabajador.update(archivo, { error: 'Cancelado' }): false">
                                <CIcon name="cil-x-circle"/>
                            </CButton>

                            <CButton
                                v-else-if="archivo.error && archivo.error !== 'compressing' && $refs.archivoTrabajador.features.html5"
                                content="Subir nuevamente Archivo"
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                title="Recargar archivo"
                                color="info" 
                                size="sm"
                                @click.prevent="archivo.active || $refs.archivoTrabajador.update(archivo, {active: true,error: '',progress: '0.00'})">
                                <CIcon :content="$options.freeSet.cilLoopCircular"/>
                            </CButton>

                            <CButton
                                v-else
                                content="Subir Archivo"
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                title="Subir archivo"
                                color="success" 
                                size="sm"
                                @click.prevent="archivo.success || archivo.error === 'compressing'? false: $refs.archivoTrabajador.update(archivo, { active: true })">
                                <!--<CIcon :content="$options.freeSet.cilCloudUpload"/>-->
                                <span class="pi pi-cloud-upload" style="font-size: 1rem; color:white"></span>
                                
                            </CButton>

                            <CButton
                            v-if="!archivo.active"
                                content="Quitar Archivo"
                                v-tippy="{ 
                                    placement: 'top',
                                    arrow: true, 
                                    arrowType: 'round', 
                                    animation:'fade',
                                    theme: 'google',
                                    followCursor : true,
                                }"
                                title="Quitar archivo"
                                color="danger" 
                                size="sm"
                                @click.prevent="$refs.archivoTrabajador.remove(archivo)">
                                <!--<CIcon :content="$options.freeSet.cilTrash"/>-->
                                
                                <span class="pi pi-trash" style="font-size: 1rem; color:white"></span>
                            </CButton>
                            
                        </CButtonGroup>
                    </template>
                    
                    
                </a-table>
            </div>
            
            <div class="vm-footer">
                <CRow>
                    <CCol>
                        
                        <CButton
                            class="float-right" 
                            content="Cerrar"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : true,
                            }"
                            color="danger" 
                            size="sm"
                            @click="cerrarMostrarSubirDoc">
                            <CIcon name="cil-x-circle"/> Cerrar
                        </CButton>
                    </CCol>
                </CRow>
            </div>
        </Modal>
    </div>
</template>
<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'

    import { freeSet } from "@coreui/icons";
    
    import FileUpload from "vue-upload-component";
    
    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    export default {
        name: "ModalSubirDocTrabajador",
        freeSet,
        props: {
            mostrarModal: Boolean,
            linkSubirArchivoTrabajador: String

        },
        components: {
            'Modal': VueModal,
            FileUpload,
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,

                docxRegex: /\.docx$/i,
                pdfRegex: /\.pdf$/i,
                
                headers: { Authorization: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario },
                archivosSubirTrabajador: [], 
                //mostrarModal: false,
                //linkSubirArchivoTrabajador: '',
                columnasSubidaArchivoTrabajador: [
                    { title: 'Tipo', scopedSlots: { customRender: 'tipoArchivo' }, width: '5%', align: 'left' ,ellipsis: false},
                    { title: 'Nombre Archivo', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Progreso', scopedSlots:{ customRender: 'progreso' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Tamaño', scopedSlots: { customRender: 'tamanio' }, width: '10%', align: 'left', ellipsis: false},
                    { title: 'Velocidad', scopedSlots: { customRender: 'velocidad' }, width: '10%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, width: '20%', align: 'center', ellipsis: false},
                ],

                docRecibido: false

            };
        },
        created: function () {},
        beforeMount() {},
        methods: {
            abrirModal: function(){
                this.mostrarModal = true
            },
            cerrarMostrarSubirDoc: function(){
                //this.mostrarModalSubirDoc = false
                this.archivosSubirTrabajador = []
                this.$emit('cerrarModal', false)

            },
            validarTipoArchivo: function(nombreArchivo){
                if(this.docxRegex.test(nombreArchivo)) { return 1 }
                else if (this.pdfRegex.test(nombreArchivo)) { return 2 }
                else{ return 3 }
            },

            inputFile(newFile, oldFile) {
                if (newFile && oldFile) {
                    // update
                    if (newFile.active && !oldFile.active) {
                    // beforeSend
                    // min size
                        if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
                            this.$refs.archivoTrabajador.update(newFile, { error: "size" });
                        }
                    }
                    if (newFile.progress !== oldFile.progress) {
                    // progress
                    
                    }
                    if (newFile.error && !oldFile.error) {
                    // error
                    }
                    if (newFile.success && !oldFile.success) {
                    // success
                        console.log("newFile.response.documento", newFile.response.documento)
                        if(!this.docRecibido){
                            this.docRecibido = true

                            this.$emit('recibirDocumentoActualizado', newFile.response.documento)
                        }
                    }
                }
                if (!newFile && oldFile) {
                    this.docRecibido = false

                    // remove
                    if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/upload/delete?id=' + oldFile.response.id,
                    // })
                    }
                }
                // Automatically activate upload
                if (
                    Boolean(newFile) !== Boolean(oldFile) ||
                    oldFile.error !== newFile.error
                ) {
                    if (this.uploadAuto && !this.$refs.archivoTrabajador.active) {
                    this.$refs.archivoTrabajador.active = true;
                    }
                }
            },

        },
    };
</script>