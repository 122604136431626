<template>
    <Modal
      v-model="prevue"
      :title="'Vista previa del documento'"
      :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
      @before-open="beforeOpen"
      @close="cerrarModal"
    >
        <CRow>
            <CCol md="8">
                <multiselect 
                    v-model="trabajadorSeleccionado" 
                    :custom-label="customTrabajador" 
                    select-label=""
                    selectedLabel=""
                    deselect-label=""
                    track-by="nombreTrabajador" 
                    placeholder="Buscar Trabajador" 
                    open-direction="bottom" 
                    :options="(empresa.trabajadores.length>0)?empresa.trabajadores:[]" 
                    :multiple="false" 
                    :searchable="true"
                    :loading="isLoadingTrabajadores" 
                    @select="seleccionarTrab">
                    
                </multiselect>

            </CCol>
            <CCol md="3">
              <CDropdown 
                  v-if="trabajadorSeleccionado != null"
                  color="drpdwn-menu"
                  class="mt-1 d-inline-block"
                  size="sm">
                  <template #toggler-content>
                      <CIcon :content="$options.freeSet.cilApplications"
                          :style="{color:(trabajadorSeleccionado.documentos.length>0)?'green':'#A31652'}" 
                      />
                  </template>
                  
                  <div role="group" v-bind:key="doc.nombreDocumento" v-for="doc in trabajadorSeleccionado.documentos">
                    
                        <CDropdownHeader class="text-center">
                            <span>
                                {{doc.tipo}} 
                                <span v-if="tipoDocumentoSelected.valor == 'contratos'">{{formatFechaDocumentoNormal(doc.fechaContrato)}}</span>
                                <span v-if="tipoDocumentoSelected.valor == 'finiquitos'">{{formatFechaDocumentoNormal(doc.fechaEmision)}}</span>
                            </span>
                            <div v-if="doc.documento != null" class="small text-muted">{{doc.documento.nombreDocumento}}</div>
                            <div>
                                <CBadge v-if="doc.documento != null?(doc.documento.idDocumento == documentoSeleccionado.idDocumento)?true:false:false"
                                    style="font-weight: normal;"
                                    shape="pill"
                                    :color="'success'">                                        
                                    {{doc.documento != null?(doc.documento.idDocumento == documentoSeleccionado.idDocumento)?'Viendo ':'':''}}
                                </CBadge>
                            </div>
                        </CDropdownHeader>
                        
                        <CDropdownItem 
                            v-show="doc.documento == null">
                            <CIcon
                                class="mr-1"
                                v-c-tooltip.hover="{
                                    content: 'No hay documentos',
                                    placement: 'left',
                                }"
                                :content="$options.freeSet.cilMeh"
                            /> Sin Documento
                        </CDropdownItem>
                        <CDropdownItem v-show="doc.documento != null" @click="seleccionarDocumento(doc.documento)">
                            <CIcon class="mr-1" :content="$options.freeSet.cilZoomIn" /> Ver Documento
                        </CDropdownItem>
                        <CDropdownItem v-show="doc.documento != null" @click="descargarDocumento(doc.documento)">
                            <CIcon class="mr-1" :content="$options.freeSet.cilCloudDownload" /> Descargar Documento
                        </CDropdownItem>
                        <CDropdownDivider />       
                    </div>  
                  
              </CDropdown>
          
              <CButton
                    size="sm"
                    class="ml-1 d-inline-block btnPrincipalVapp"
                    @click="descargarDocumento(documentoSeleccionado)"
                    ><CIcon
                        :content="$options.freeSet.cilCloudDownload"
                        v-c-tooltip.hover="{
                            content: 'Descargar Documento visualizado',
                            placement: 'over',
                        }"
                    />
                </CButton>
                
          </CCol>

        </CRow>
        <CRow>
            <CCol>
                <iframe
                    class="mt-2"
                    :src="trabajadorSeleccionado == null || documentoSeleccionado == null?'':documentoSeleccionado.viewDocumento.replace('view', 'preview')"
                    width="100%"
                    height="400"
                    allow="autolay">
                </iframe>
            </CCol>
        </CRow>
      <CRow>
        <CCol sm="12" md="12" lg="12" align="right">
          <CButton
            md="3"
            class="btnPrincipalVapp m-3"
            style="margin: 20%"
            @click="cerrarModal"
            >Cerrar</CButton
          ></CCol
        ></CRow
      >
    </Modal>
    
  </template>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <script>
      import cons from "@/const";
      import Multiselect from "vue-multiselect";
      import axios from "axios";
  
      import VueModal from "@kouts/vue-modal";
      import "@kouts/vue-modal/dist/vue-modal.css";
  
      import { freeSet } from "@coreui/icons";
      import apiUrls from '@/views/utils/apiUrls'
      
      import { format, formatDistance, formatRelative, subDays } from 'date-fns'
      import es from 'date-fns/locale/es'
      window.locale = 'es'
  
  export default {
    name: "ModalDocsView",
    freeSet,
    props: {
      prevue: Boolean,
      trabajadorprev: Object,
      empresa: Object,
      periodoSelected: Object,
      mesSelected: Object,
      documento: Object,
      tipoDocumentoSelected: Object,
      documentos: Array,
      web: Object
    },
    components: {
      Multiselect,
      Modal: VueModal,
    },
    data() {
      return {
          urlBase: cons.port+"://" + cons.ipServer,
                  
          tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,
          trabajadorSeleccionado: null,
          documentoSeleccionado: null,
  
        contador: 0,
        derizq: null,
        estado: null,
        haydoc: true,
        haydocsig: true,
  
        isLoadingTrabajadores: false,
  
        trabajadoresModal: [],
  
        currentPage: 1,
        totalPages: 20,
        
        strFiltroTrabajadores: "",
  
        rqstTrabs: null,
        isEnviandoDocumentos: false,
        msgEnvio: "",
  
        
  
      };
    },
    created: function () {
    },
    beforeMount() {
      let cookieUsername = this.$cookie.get("userLogginToken");
      if (cookieUsername === null || cookieUsername === "") {
        this.$router.push("/pages/login");
      }
      this.msgEnvio = ""
      //this.trabajadorprev = this.trabajador;
      
    },
    mounted() {
    },
    methods: {
      beforeOpen: function(){
          
          this.currentPage = 1
          //this.getTrabajadoresFiltrados()
          this.trabajadorSeleccionado = this.trabajadorprev;
          this.documentoSeleccionado = this.documento;
      },
      reachedEndOfList(reached) {
        if (reached) {
          if(!this.isLoadingTrabajadores){
            if(this.hasNextPage())
            
              this.getTrabajadores()
          }
        }
      },
      hasNextPage() { return this.currentPage + 1 <=this.totalPages },
      seleccionarDocumento: function(documento) {
        this.documentoSeleccionado = documento;
      },
      seleccionarTrab: function(trab){
          //this.trabajadorprev = trab;
            this.documentoSeleccionado = null
            if(trab.documentos.length > 0){
              trab.documentos.forEach(docu => {
                if(docu.documento != null){
                    this.documentoSeleccionado = docu.documento
                }
              })
            }
            //this.documentoSeleccionado = trab.documentos[0].documento
            
          
        /*this.portalCliente.estadosDocumentos.forEach(documento=>{
          if(documento.idDocumento == trab.documento.idDocumento){
            if(documento.estadoDocumento != trab.documento.estadoDocumento){
              trab.documento.respaldoEstado = trab.documento.estadoDocumento
              trab.documento.estadoDocumento = 2
              trab.documento.agregadoRechazo = true
            }
          }
        })*/
      },
      formatFechaDocumentoNormal : function(fechaDoc){
          if (fechaDoc != null) {
              return format(new Date(fechaDoc), cons.formatoFechaDocumentos, {locale: es})
          } else {
              return "-";
          }
          
      },
      asyncFind: function(query){
  
        this.strFiltroTrabajadores = query
        this.currentPage = 1
        this.trabajadoresModal = [{ rutTrabajador: '0', nombreTrabajador: 'Buscando...', documento:{hayDocumento: false},  $isDisabled: true }]
        this.getTrabajadoresFiltrados()
      },
      getTrabajadores: function(){
        
          if(this.rqstTrabs != null){
              this.rqstTrabs.cancel()
              this.rqstTrabs.msg = "cancelado";
              this.rqstTrabs = null;
              this.isLoadingTrabajadores = false;
          }
          const axiosSource = axios.CancelToken.source();
          this.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };
          
  
          this.isLoadingTrabajadores = true;
          //this.trabajadoresModal = []
          let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`
  
          
          axios({
            method: "POST",
            url: url,
            cancelToken: axiosSource.token,
            "data": {
                "paginacionTrabajadores": {
                    "strFiltro": this.strFiltroTrabajadores,
                    "actual": this.currentPage,
                    "tamanioPagina": 10
                }
            },
            headers: {
              Authorization: `${this.tokenLogin}`,
            },
          }).then((result) => {
            //if(this.currentPage == 1)
              //this.trabajadoresModal = []
  
  
              this.totalPages = Number((result.data.totalTrabajadores/10).toFixed(0))
  
              if(this.trabajadoresModal.length == 1 && this.trabajadoresModal[0].rutTrabajador == '0'){
                  this.trabajadoresModal = []
              }
  
              this.trabajadoresModal = this.trabajadoresModal.concat(result.data.trabajadores)
              this.currentPage++
              this.isLoadingTrabajadores = false;
              this.rqstTrabs = null
            },
            cancel=>{
              this.rqstTrabs = null
              },
            (error) => {
              this.isLoadingTrabajadores = false;
              this.rqstTrabs = null
  
            }
          )
          .finally(() => {
          });
      },
      getTrabajadoresFiltrados: function(){
        
          if(this.rqstTrabs != null){
                this.rqstTrabs.cancel()
                this.rqstTrabs.msg = "cancelado";
                this.rqstTrabs = null;
                this.isLoadingTrabajadores = false;
            }
          const axiosSource = axios.CancelToken.source();
          this.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };
  
          this.isLoadingTrabajadores = true;
          //this.trabajadoresModal = []
  
          let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`
          axios({
            method: "POST",
            url: url,
            cancelToken: axiosSource.token,
            "data": {
                "paginacionTrabajadores": {
                    "strFiltro": this.strFiltroTrabajadores,
                    "actual": this.currentPage,
                    "tamanioPagina": 10
                }
            },
            headers: {
              Authorization: `${this.tokenLogin}`,
            },
          }).then((result) => {
            //if(this.currentPage == 1)
              //this.trabajadoresModal = []
  
  
              this.totalPages = Number((result.data.totalTrabajadores/10).toFixed(0))
  
              this.trabajadoresModal = result.data.trabajadores
              this.currentPage++
              this.isLoadingTrabajadores = false;
              this.rqstTrabs = null
            },
            cancel=>{
              this.rqstTrabs = null
              },
            (error) => {
              this.isLoadingTrabajadores = false;
              this.rqstTrabs = null;
  
            }
          )
          .finally(() => {
          });
      },
      enviarDocumentoTrabajador: function(trabajador, idEmpresa){
          this.msgEnvio = ""
        trabajador.documento.isLoadiongEnvio = true
        var url = cons.port + "://" + cons.ipServer + "/" + "enviar_documento_trabajador/"
        
        axios({
            method: "POST",
            url: url,
              "data": {
                  "trabajadoresEnviar": {
                      "enviarTodos": false,
                      "idEmpresa": idEmpresa,
                      "anioPeriodo": this.periodoSelected.valor,
                      "mesPeriodo": this.mesSelected.valor,
                      "tipoDoc": this.tipoDocumentoSelected.valor,
                      "trabajadores":[{"rutTrabajador": trabajador.rutTrabajador}]
                  }
              },
              headers: {
                  Authorization: `${this.tokenLogin}`,
              },
            })
            .then((result) => {
              this.msgEnvio = result.data.mensaje
            },
            (error) => {
              this.msgEnvio =result.data.mensaje
            }
            )
            .finally(() => {
                trabajador.documento.isLoadiongEnvio = false
            });
          },
  
  
      vistaprevia: function (trabajador) {
        this.trabajadorSeleccionado = trabajador;
        this.contador = this.trabajadores.indexOf(this.trabajadorprev);
      },
      customTrabajador: function (trabajador) {
        return `${trabajador.rutTrabajador} – ${trabajador.nombreTrabajador} ${!(trabajador.documentos.length != 0) ? "(Sin Documentos)" : ""}`; //${!trabajador.documento.hayDocumento ? "(Sin Documento)" : ""}`;
      },
      descargarDocumento: function (documento) {
        var link = documento.urlDocumento;
        var fileLink = document.createElement("a");
        fileLink.href = link;
        fileLink.setAttribute("download", documento.nombreDocumento);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
      /*anteriorsiguiente: function (derizq) {
        this.haydoc = true
        this.haydocsig = true
        var posTrabajadorActual = this.trabajadores.indexOf(this.trabajadorprev)
        if (derizq == 0) {
          var hayDocumento = false;
          var hayDocumentoAnt = false;
  
          for(var pos=posTrabajadorActual-1;pos>=0;pos--){
            
            if(hayDocumento){
              if(pos>=0){
                if(this.trabajadores[pos].documento.hayDocumento){
                  hayDocumentoAnt = true;
                }
              }
            }else{
              if(this.trabajadores[pos].documento.hayDocumento){
                this.trabajadorprev = this.trabajadores[pos]
                hayDocumento = true;
              }
            }
            if(hayDocumentoAnt){
              break
            }
          }
          if(!hayDocumentoAnt){
            this.haydoc=false;
          }
        }
        if (derizq == 1) {
          
          var hayDocumento = false
          var hayDocumentoSig = false
          
          for(var pos=posTrabajadorActual+1;pos<this.trabajadores.length-1;pos++){
  
            if(hayDocumento){
              if(pos<=this.trabajadores.length-1)
                if(this.trabajadores[pos].documento.hayDocumento)
                  hayDocumentoSig = true
            }else{
              if(this.trabajadores[pos].documento.hayDocumento){
                this.trabajadorprev = this.trabajadores[pos]
                
                hayDocumento = true;
              }
            }
            
            if(hayDocumentoSig)
              break
              
          
          }
  
          if(!hayDocumentoSig)
            this.haydocsig=false;
          
          
        }
      },*/
  
      cerrarModal: function () {
        //this.prevue = false;
        this.web.mostrarVisorPDF = false;
        /*if(this.portalCliente.estadosDocumentos.length>0){
          this.$notification.success("Tiene documento/s rechazado/s", {  timer: 5, position:"bottomRight", showCloseIcn:true});
        }*/
      },
  
      /*rechazarDocumentos: function () {
        this.trabajadorprev.documento.respaldoEstado = this.trabajadorprev.documento.estadoDocumento
        this.trabajadorprev.documento.estadoDocumento = 2
        this.trabajadorprev.documento.agregadoRechazo = true
  
        this.portalCliente.estadosDocumentos.push(this.trabajadorprev.documento);
  
        this.$notification.success("documento rechazado", {  timer: 5, position:"bottomRight", showCloseIcn:true});
  
  
      },*/
      /*quitarRechazoDocumento: function(){
        this.portalCliente.estadosDocumentos.forEach((documento, index)=>{
          if(documento.idDocumento == this.trabajadorprev.documento.idDocumento){
            this.trabajadorprev.documento.estadoDocumento = documento.respaldoEstado
            this.trabajadorprev.documento.agregadoRechazo = false
            this.portalCliente.estadosDocumentos.splice(index, 1)
          }
        })
  
      }*/
    },
  };
  </script>